import { createSelector } from '@reduxjs/toolkit';

import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';

import { selectExchanges } from 'src/store/shared/exchanges/selectors';
import type { JsonExchange } from 'src/store/shared/exchanges/types';

import type { AccountsState } from 'src/store/shared/accounts/types';

import type { AutoCompleteItem } from 'src/store/types';
import type { LoansState, JsonLoan } from './types';

interface Store {
  loans: {
    index: LoansState;
  };
  accounts: AccountsState;
}

export const selectError = (store: Store) => store.loans.index.error;
export const selectIsListenSent = (store: Store) => store.loans.index.listenStatus === 'success';

export const selectLoansList = (store: Store) => store.loans.index.list;
export const selectCompanies = (store: Store) => store.loans.index.companies;

export const selectComments = (store: Store) => store.loans.index.comments;
export const selectIsOverviewLoading = (store: Store) => store.loans.index.isOverviewLoading;

export const selectIsNewLoanCommentModalOpen = (store: Store) =>
  store.loans.index.isNewLoanCommentModalOpen;
export const selectIsCommentSubmitting = (store: Store) =>
  store.loans.index.resUpdateComment === 'pending';
export const selectIsCommentSubmitted = (store: Store) =>
  store.loans.index.resUpdateComment === 'success';

export const selectIsNewLoanModalOpen = (store: Store) => store.loans.index.isNewLoanModalOpen;
export const selectIsLoanSubmitting = (store: Store) =>
  store.loans.index.resNewLoan === 'pending' || store.loans.index.resUpdateLoan === 'pending';
export const selectIsLoanSubmitted = (store: Store) =>
  store.loans.index.resNewLoan === 'success' || store.loans.index.resUpdateLoan === 'success';

export const selectIsSidebarOpen = (store: Store) => store.loans.index.isSidebarOpen;
export const selectTargetLoan = (store: Store) => store.loans.index.targetLoan;
export const selectTargetExchange = (store: Store) => store.loans.index.targetExchange;

export const selectOverviewActiveTab = (store: Store) => store.loans.index.overviewActiveTab;

export const selectTermChangesLog = (store: Store) => store.loans.index.termChangesLog;
export const selectIsTermChangesLogLoading = (store: Store) =>
  store.loans.index.termsChangesLogLoadingStatus === 'pending';

// company options/suggestions for autocomplete in NewLoanModal form

export const selectCompanyOptions = createSelector(selectCompanies, (items: string[]) => {
  const companyOptions: AutoCompleteItem[] = items.map((company: string) => ({
    key: company,
    value: company,
    label: company,
  }));

  return companyOptions;
});

// bot table with exchange details

// currentLoan could be:
//  - undefined – the initial value
//  - JsonLoan – if currentLoan is selected from the loans list
//  - null - if currentLoan could not be found in the loans list
export const selectCurrentLoan = createSelector(
  selectTargetLoan,
  selectLoansList,
  (targetLoan: string, items: JsonLoan[] | null) => {
    if (items === null || !targetLoan) {
      return undefined;
    }

    return find(items, { id: +targetLoan }) ?? null;
  },
);

export const selectDefaultLoan = createSelector(
  selectLoansList,
  (items: JsonLoan[] | null) => first(sortBy(items, 'id')) ?? null,
);

export const selectNextLoan = createSelector(
  selectTargetLoan,
  selectLoansList,
  (targetLoan: string, items: JsonLoan[] | null) => {
    const sortedItems = sortBy(items, 'id');
    let nextIdx = findIndex(sortedItems, { id: +targetLoan }) + 1;
    if (Number(targetLoan) === nextIdx) {
      nextIdx += 1;
    }
    const nextLoan: JsonLoan | undefined =
      (nextIdx < sortedItems.length && sortedItems[nextIdx]) || undefined;
    return nextLoan;
  },
);

export const selectPrevLoan = createSelector(
  selectTargetLoan,
  selectLoansList,
  (targetLoan: string, items: JsonLoan[] | null) => {
    const sortedItems = sortBy(items, 'id');
    const prevIdx = findIndex(sortedItems, { id: +targetLoan }) - 1;
    const prevLoan: JsonLoan | undefined = (prevIdx >= 0 && sortedItems[prevIdx]) || undefined;
    return prevLoan;
  },
);

export const selectCurrentExchange = createSelector(
  selectTargetExchange,
  selectExchanges,
  (targetExchange: string, items: JsonExchange[]) => {
    const currentExchange: JsonExchange | undefined = targetExchange
      ? (find(items, { id: +targetExchange }) ?? undefined)
      : undefined;
    return currentExchange;
  },
);

export const selectLoanLogMessagesCommitedFunds = createSelector(selectTermChangesLog, (messages) =>
  messages
    .filter((entry) => entry.Message.startsWith('Committed funds'))
    .map((entry, idx) => ({ ...entry, key: entry.ID! || idx })),
);

const filterAndSortLoans = (loans: JsonLoan[] | null, sortKey: string, searchValue: string) => {
  if (!loans) return [];

  let filteredData = loans;

  if (searchValue.length >= 2) {
    const parts = searchValue.toLowerCase().split(' ');

    filteredData = loans.filter((item) => {
      const itemString = `${item.name}`.toLowerCase();
      return parts.every((token) => itemString.includes(token));
    });
  }

  return filteredData.slice().sort((a, b) => {
    switch (sortKey) {
      case 'name_asc':
        return a.name.localeCompare(b.name);
      case 'name_desc':
        return b.name.localeCompare(a.name);
      case 'created_asc':
        return a.id - b.id;
      case 'created_desc':
        return b.id - a.id;
      default:
        return 0;
    }
  });
};

export const selectSortKey = (state: any) => state.loans.index.sortKey;
export const selectSearchInput = (state: any) => state.loans.index.searchInput;

export const selectSortedLoans = createSelector(
  [selectLoansList, selectSortKey, selectSearchInput],
  (loans, sortKey, searchValue) => filterAndSortLoans(loans, sortKey, searchValue),
);
