import type { Middleware } from '@reduxjs/toolkit';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { parseResponse } from 'src/store/utils';
import { selectTargetLoan } from 'src/store/loans/selectors';
import { selectTargetProject } from 'src/store/retainers/selectors';
import type { RootState } from 'src/store';
import { Commands } from './commands';

export const handleWsMessageMiddleware: Middleware = (store) => (next) => (action) => {
  const { payload, type } = action as any;

  if (type === actionOnMessage.toString()) {
    const allowedCommands = Object.values(Commands);
    const { json, skipProcessing } = parseResponse(payload, allowedCommands);

    if (skipProcessing || !json) {
      return next(action);
    }

    const { id, loanID, projectID } = json;

    let responseLoanId = loanID;
    if (!responseLoanId && id >= 5000) {
      responseLoanId = id;
    }

    let responseProjectId = projectID;
    if (!responseProjectId && id < 5000 && id >= 0) {
      responseProjectId = id;
    }

    if (responseLoanId) {
      const currentLoan = selectTargetLoan(store.getState() as RootState);

      if (currentLoan && +currentLoan !== responseLoanId) {
        return null;
      }

      return next(action);
    }

    if (responseProjectId) {
      const currentProject = selectTargetProject(store.getState() as RootState);

      if (currentProject && +currentProject !== responseProjectId) {
        return null;
      }

      return next(action);
    }
  }

  return next(action);
};
