import * as Sentry from '@sentry/react';
import type { RootState } from 'src/store/index';

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer() {
    return null;
  },
  stateTransformer(state: RootState) {
    return {
      ...state,
      auth: {
        userId: state.auth.userId,
      },
      signup: {
        email: state.signup.email,
        fullName: state.signup.fullName,
        company: state.signup.company,
      },
      apiKeys: {
        ...state.apiKeys,
        modalInitialValue: null,
        exchange: null,
      },
      'apiKeys/rtk': null,
    };
  },
});
