import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus } from 'src/store/types';

export enum LoanFundsCommands {
  LoanFunds = 'LoanFunds',
  AddCommittedFunds = 'AddCommittedFunds',
}
export interface JsonLoanFundsSuccess {
  command: 'LoanFunds';
  funds: LoanFundsItem[];
}
export interface LoanFundsItem {
  key: number;
  Amount: number;
  Token: string;
  Author: string;
}
export interface LoanFundsState {
  committedFunds: LoanFundsItem[];
  initialCommittedFunds: number | null;
  status: RequestStatus;
  error: GenericError | null;
}
