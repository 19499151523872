import type { BalanceTableOptsState } from 'src/store/shared/balance-table-opts/types';

interface Store {
  loans: {
    balancesUI: BalanceTableOptsState;
  };
}

export const selectIsDuplicatesVisible = (store: Store) =>
  store.loans.balancesUI.isDuplicatesVisible;
export const selectIsInitialBalanceUsed = (store: Store) =>
  store.loans.balancesUI.isInitialBalanceUsed;
export const selectViewMode = (store: Store) => store.loans.balancesUI.viewMode;
export const selectViewGroupBy = (store: Store) => store.loans.balancesUI.viewGroupBy;
export const selectViewTarget = (store: Store) => store.loans.balancesUI.viewTarget;
export const selectViewRounding = (store: Store) => store.loans.balancesUI.viewRounding;
export const selectIsSmallBalancesVisible = (store: Store) =>
  store.loans.balancesUI.isSmallBalancesVisible;

export const selectShowDlaBots = (store: Store) => store.loans.balancesUI.showDlaBots;
export const selectIgnoreDlaBots = (store: Store) => store.loans.balancesUI.ignoreDlaBots;

export const selectIsIgnoreOtherTokens = (store: Store) =>
  store.loans.balancesUI.isIgnoreOtherTokens;

export const selectIgnoreTokens = (store: Store) => store.loans.balancesUI.ignoreTokens;
