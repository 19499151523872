import React from 'react';

import * as Sentry from '@sentry/react';

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { PACKAGE_VERSION } from 'src/constants';

const env = import.meta.env.VITE_DOTENV;

console.debug('Running application with environment:', env);

Sentry.init({
  // cloud
  // dsn: 'https://005d96f03f2ca71d8ac005c4ae69a3e4@o4506025815769088.ingest.sentry.io/4506025823436800',

  // self-hosted
  dsn: 'https://1463d5753415ac3cfb36cb795172dee2@sentry.internal.skynettrading.com/2',

  // debug: true,

  // version format here should be aligned with the version in the Github Action
  release: `${import.meta.env.VITE_SENTRY_PROJECT}@v${PACKAGE_VERSION}`,

  environment: env,

  denyUrls: [/https?:\/\/localhost/],
  // allowUrls: [/https?:\/\/loan.skynettrading.com/],

  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  beforeBreadcrumb: (breadcrumb) => {
    if (breadcrumb.category === 'console') {
      return null;
    }
    return breadcrumb;
  },

  beforeSend(event) {
    const filteredEvent = { ...event };

    if (filteredEvent.request) {
      delete filteredEvent.request.headers?.Authorization;
      delete filteredEvent.request.headers?.['x-fingerprint'];
      delete filteredEvent.request.cookies;
    }

    return filteredEvent;
  },

  // Performance Monitoring
  // Capture 100% of the transactions, reduce in production!
  tracesSampleRate: 1.0,

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // 'localhost',
    /^https:\/\/loan\.skynettrading\.com\//,
  ],

  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.1,

  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
});
