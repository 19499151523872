import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { rtkQueryErrorActions } from 'src/store/error/slice';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action: any) => {
  if (isRejectedWithValue(action) && ['query', 'mutation'].includes(action.meta.arg.type)) {
    if (
      (action.payload.status === 404 && action.meta.arg.endpointName === 'getColdWallets') ||
      (action.payload.status === 404 &&
        action.meta.arg.endpointName === 'getWalletProjectSnapshot') ||
      (action.payload.status === 404 && action.meta.arg.endpointName === 'getWalletSnapshot')
    ) {
      // Because the lack of wallets comes in the form of an error 404, we don't want to show an error notification
      return next(action);
    }
    api.dispatch(rtkQueryErrorActions.setError(action.payload));
    return next(action);
  }

  return next(action);
};
