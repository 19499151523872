import type { PayloadAction } from '@reduxjs/toolkit';
import { combineReducers, createSlice } from '@reduxjs/toolkit';

import type { GenericError } from 'src/store/utils/errors';
import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';

import { startListening } from 'src/store/middleware/listenerMiddleware';
import { balancesActions } from 'src/store/shared/balances/slice';
import { tradingActions } from 'src/store/shared/trading/slice';
import type { JsonBalance } from 'src/store/shared/balances/types';
import type { RequestStatus } from 'src/store/types';
import type { JsonLinechartOverview } from 'src/store/loans/types';
import { tasksActions } from 'src/store/shared/tasks/slice';
import retainersProjectsReducer from './projects/slice';
import retainersAdminPanelReducer from './admin-panel/slice';
import retainersBalancesUIReducer from './balance-table-opts/slice';
import retainerTermsReducer from './terms/slice';
import retainersKpiReducer from './kpi/slice';
import retainersThresholdReducer from './threshold/slice';

import { hideProject, listen } from './actions';
import type { JsonRetainersExchange, JsonRetainersExchangesSuccess } from './types';
import { RetainerCommands } from './types';

export interface JsonProject {
  id: number;
  name: string;
  title: string;
  tabs: string[];
}

export interface RetainersState {
  error: null | GenericError;
  exchanges: JsonRetainersExchange[] | null;
  balances: JsonBalance[] | null;
  // Retainers (right-hand) sidebar and header
  listenStatus: RequestStatus;
  isSidebarOpen: boolean;
  targetProject: string;
  targetExchange: string;
  overviewActiveTab: string;

  linechartWeek: JsonLinechartOverview;
  linechartYear: JsonLinechartOverview;
  linechartRange: 'Week' | 'Year';
  linechartStacked: boolean;

  isNewProjectModalOpen: boolean;
}

const allowedCommands = Object.values(RetainerCommands);

const initialState: RetainersState = {
  error: null,
  exchanges: null,
  balances: null,
  // Loans (right-hand) sidebar and header
  listenStatus: null,
  isSidebarOpen: false,
  targetProject: '',
  targetExchange: 'balances',
  overviewActiveTab: 'projects',

  linechartWeek: [],
  linechartYear: [],
  linechartRange: 'Week',
  linechartStacked: false,

  isNewProjectModalOpen: false,
};

const retainersSlice = createSlice({
  name: 'retainers',
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },

    setTargetProject(state, action) {
      state.targetProject = action.payload.projectId;
    },

    setTargetExchange(state, action) {
      state.targetExchange = action.payload.exchangeId || 'balances';
    },

    setTarget(state, action) {
      state.listenStatus = null;
      state.targetProject = action.payload.projectId;
      state.targetExchange = action.payload.exchangeId || 'balances';
    },

    setOverviewActiveTab(state, action: { payload: string }) {
      state.overviewActiveTab = action.payload;
    },

    setLinechartRange(state, action) {
      state.linechartRange = action.payload.range;
    },

    setLinechartStacked(state, action) {
      state.linechartStacked = action.payload.stacked;
    },

    openNewProjectModal(state) {
      state.isNewProjectModalOpen = true;
    },

    closeNewProjectModal(state) {
      state.isNewProjectModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    // listen

    builder.addCase(listen.pending, (state, action: any) => {
      const { isInitCall, section, projectID } = action.meta.arg;
      if (isInitCall) {
        state.listenStatus = 'pending';

        if (section === 'overview') {
          if (!state.targetProject) {
            state.targetProject = projectID;
          }
        }
      }
    });

    builder.addCase(listen.fulfilled, (state, action: any) => {
      console.debug('retainers/overview', action);
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall && !['overview'].includes(section)) {
        state.listenStatus = 'success';
      }
    });

    // on WS message

    builder.addCase(actionOnMessage, (state, action: PayloadAction<any>) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(`retainers/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      const { command, okCommand } = json;

      if (error) {
        state.error = error;
        return;
      }

      if (command === 'ok') {
        switch (okCommand) {
          // case 'EnableAccount':
          //   state.servers = state.servers.map(updateAccountEnableStatus(json));
          //   break;
          default:
        }
      }

      if (command === RetainerCommands.RetainerOverview) {
        const { exchanges, balances, linechartWeek, linechartYear }: JsonRetainersExchangesSuccess =
          json;

        state.exchanges = exchanges;
        state.balances = balances;
        state.linechartWeek = linechartWeek;
        state.linechartYear = linechartYear;

        state.listenStatus = 'success';
      }
    });
  },
});

export const retainersActions = {
  ...retainersSlice.actions,
  // ...retainersProjectsReducer.actions ,
  // ...retainersAdminPanelReducer.action,
  listen,
  hideProject,
};

export default combineReducers({
  index: retainersSlice.reducer,
  projects: retainersProjectsReducer,
  balancesUI: retainersBalancesUIReducer,
  adminPanel: retainersAdminPanelReducer,
  terms: retainerTermsReducer,
  kpi: retainersKpiReducer,
  threshold: retainersThresholdReducer,
});

startListening({
  actionCreator: retainersActions.setTarget,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(balancesActions.resetList());
    listenerApi.dispatch(tradingActions.resetSession());
    listenerApi.dispatch(tradingActions.resetSession());
    listenerApi.dispatch(tasksActions.resetFilters());
  },
});
