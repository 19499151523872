import type { GenericError } from 'src/store/utils/errors';
import type { LiquidityMap } from 'src/store/loans/types';
import type { JsonLoanExchangeExtended } from 'src/store/loans/exchanges/types';
import type { RequestStatus } from 'src/store/types';

export enum LoanLiquidityCommands {
  LoanLiquidity = 'LoanLiquidity',
}

export interface JsonLoanLiquiditySuccess {
  command: LoanLiquidityCommands.LoanLiquidity;
  id: number;
  liquidity: JsonLoanLiquidity[];
}

export type ActualBotsDepth = '1' | '2' | '5' | '10' | '-1' | '-2' | '-5' | '-10';

export interface JsonLoanLiquidity {
  ExchangeID: number;
  Pair: string;
  AccountID: string;
  AccountName: string;
  Liquidity: LiquidityMap;
  ActualLiquidity: Record<ActualBotsDepth, number>;
}

export type LoanLiquidityCache = Record<number, JsonLoanLiquidity[]>;

export type LoanLiquidityViewFilters = 'no' | 'supportedPairs';
export type LoanLiquidityAggregatedValuesFilter = 'fromServer' | 'fromBots';

export interface LoanLiquidityState {
  requestStatus: RequestStatus;
  error: null | GenericError;
  list: JsonLoanLiquidity[] | null;
  listCache: LoanLiquidityCache;
  viewFilter: LoanLiquidityViewFilters;
  aggregatedValuesFilter: LoanLiquidityAggregatedValuesFilter;
}

export interface AccountLiquidity {
  accountId: string;
  accountName: string;
  volume: number;
  accountVolumeByDate: [string, number][];
  avgAccountLiquidityPlus1: number;
  avgAccountLiquidityPlus2: number;
  avgAccountLiquidityMinus1: number;
  avgAccountLiquidityMinus2: number;
  avgAccountLiquidityPlus1ByDate: [string, number][];
  avgAccountLiquidityPlus2ByDate: [string, number][];
  avgAccountLiquidityMinus1ByDate: [string, number][];
  avgAccountLiquidityMinus2ByDate: [string, number][];
  currentAccountLiquidityPlus1: number;
  currentAccountLiquidityPlus2: number;
  currentAccountLiquidityMinus1: number;
  currentAccountLiquidityMinus2: number;
  actualAccountLiquidityPlus1: number;
  actualAccountLiquidityPlus2: number;
  actualAccountLiquidityMinus1: number;
  actualAccountLiquidityMinus2: number;
}

export type AccountLiquidityTree = AccountLiquidity &
  Pick<
    JsonLoanExchangeExtended,
    | 'avgMarketLiquidityMinus1'
    | 'avgMarketLiquidityPlus1'
    | 'avgMarketLiquidityMinus2'
    | 'avgMarketLiquidityPlus2'
    | 'kpi'
    | 'exchange'
  > & {
    key: string;
  };

export interface JsonLoanLiquidityExtended extends JsonLoanExchangeExtended {
  liquidityByAccount: AccountLiquidity[];
  botsVolume: number;
  aggregatedValuesFilter: LoanLiquidityAggregatedValuesFilter;
  avgBotsLiquidityPlus1: number;
  avgBotsLiquidityPlus2: number;
  avgBotsLiquidityMinus1: number;
  avgBotsLiquidityMinus2: number;
  avgBotsLiquidityPlus1ByDate: [string, number][];
  avgBotsLiquidityPlus2ByDate: [string, number][];
  avgBotsLiquidityMinus1ByDate: [string, number][];
  avgBotsLiquidityMinus2ByDate: [string, number][];
  currentBotsLiquidityPlus1: number;
  currentBotsLiquidityPlus2: number;
  currentBotsLiquidityMinus1: number;
  currentBotsLiquidityMinus2: number;
  actualBotsLiquidityPlus1: number;
  actualBotsLiquidityPlus2: number;
  actualBotsLiquidityMinus1: number;
  actualBotsLiquidityMinus2: number;
}

export interface JsonLoanLiquidityExtendedTree extends JsonLoanLiquidityExtended {
  key: string;
  children: (AccountLiquidityTree & { key: string })[];
}
