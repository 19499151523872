import { createSlice } from '@reduxjs/toolkit';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { parseResponse } from 'src/store/utils';
import { LoanCommands } from 'src/store/loans/types';
import type { JsonHistoryResponse, LoansOverviewState } from './types';
import { LoansOverviewCommands } from './types';

const initialState: LoansOverviewState = {
  error: null,
  requestStatus: null,
  // UI params for linechart
  linechartRange: 'Week',
  linechartStacked: false,
  linechartWeek: [],
  linechartYear: [],
};

const allowedCommands = Object.values(LoansOverviewCommands);

const loansOverviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    setLinechartRange(state, action) {
      state.linechartRange = action.payload.range;
    },

    setLinechartStacked(state, action) {
      state.linechartStacked = action.payload.stacked;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionOnMessage, (state, action: any) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(`loans/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      const { command } = json;

      if (error) {
        state.error = error;

        switch (errorCommand) {
          case LoanCommands.UpdateLoan:
            state.requestStatus = 'error';
            break;
          default:
        }
      }

      if (command === LoansOverviewCommands.History) {
        const { linechartWeek, linechartYear } = json as JsonHistoryResponse;
        state.linechartWeek = linechartWeek;
        state.linechartYear = linechartYear;
      }
    });
  },
});

export const loansOverviewActions = {
  ...loansOverviewSlice.actions,
};

export default loansOverviewSlice.reducer;
