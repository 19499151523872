import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus, JsonConnection, JsonWsSuccessResponse } from 'src/store/types';

export enum AccountCommands {
  Accounts = 'Accounts',
  EnableAccount = 'EnableAccount',
  DisableAccount = 'DisableAccount',
}

interface JsonLinechartPart {
  date: string;
  value: number;
}

export type JsonLinechartOverview = JsonLinechartPart[];

export type JsonLinechartExchange = JsonLinechartPart[];

export interface JsonAccount {
  name: string;
  id: string | number; // it was number for retainers/admin-panel
  inviteLink?: string; // it was missing for retainers/admin-panel
  ticker: string; // e.g. SQT/USDT
  botType: string;
  group: string;
  projectName: string;
}

export interface JsonAccountStats {
  accounts: JsonAccount[]; // subaccounts (there should be related info in pairs as well)
  status?: number; // -1 - error, 0 - offline, 1 - warning, 2 - online/success
  error?: string;
  usd: number; // current balance in USD
  linechartWeek?: JsonLinechartExchange;
  linechartYear?: JsonLinechartExchange;
  exchange: number;
}

export interface JsonAccountsSuccess {
  command: 'Accounts';
  accounts: JsonAccountStats[];
  errors: JsonConnection[];
}

export interface UpdateAccountState {
  id: string;
  status: RequestStatus;
}

export interface JsonAccountEnableSuccess extends JsonWsSuccessResponse {
  result: string;
  optional: string;
}

export interface JsonAccountDisableSuccess extends JsonWsSuccessResponse {
  id: string;
}

export enum AccountStatus {
  Enabled = 1,
  Suspend = 0,
  Disabled = -1,
  Error = -2,
}

export interface AccountsState {
  requestStatus: RequestStatus;
  error: null | GenericError;

  stats: JsonAccountStats[];
  list: JsonConnection[];
  brokenAccounts: JsonConnection[];

  resUpdateAccount: UpdateAccountState | null;
}
