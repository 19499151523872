import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import map from 'lodash/map';

import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';

import type { ExchangesState, JsonExchangesListSuccess } from './types';
import { ExchangeCommands } from './types';

const allowedCommands = Object.values(ExchangeCommands);

// Exchanges command is sent by the server on auth success,
// so we can set the initial status to 'pending' by default
const initialState: ExchangesState = {
  requestStatus: 'pending',
  error: null,

  list: [],
};

const exchangesSlice = createSlice({
  name: 'exchanges',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // on WS message

    builder.addCase(actionOnMessage, (state, action: PayloadAction<any>) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(
        `shared/exchanges/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      const { command, okCommand } = json;

      if (error) {
        state.error = error;

        switch (errorCommand) {
          // case BalanceCommand.__ACTION_NAME__:
          //   state.res__ACTION_NAME__ = { status: 'error' };
          //   break;
          default:
        }

        return;
      }

      if (command === ExchangeCommands.Exchanges) {
        const { list }: JsonExchangesListSuccess = json;

        state.list = map(list, (exchange) => ({
          id: exchange.ID,
          name: exchange.Name,
          ticker: exchange.Ticker,
        }));
        state.requestStatus = 'success';
        return;
      }

      if (command === 'ok') {
        switch (okCommand) {
          // case 'Projects':
          //   state.list = json.list;
          //   break;

          default:
        }
      }
    });
  },
});

export const exchangesActions = { ...exchangesSlice.actions };
// export const { toggleSidebar } = exchangesSlice.actions

export default exchangesSlice.reducer;
