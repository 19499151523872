import { createWsAction } from 'src/store/utils';
import type { AddBalancePayload } from 'src/store/shared/balances/types';
import { BalanceCommands } from 'src/store/shared/balances/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'src/store';
import { selectLoanBalancesExtended } from 'src/store/loans/balances/selectors';

type CloseSmallTokensPayload = object;

export const closeSmallTokens = createWsAction<CloseSmallTokensPayload, CloseSmallTokensPayload>(
  'balances/CloseSmallTokens',
  (data = {}) => ({
    command: BalanceCommands.CloseSmallTokens,
    ...data,
  }),
);

const addBalanceWs = createWsAction<AddBalancePayload, AddBalancePayload>(
  'balances/addBalanceWs',
  (data) => ({
    command: BalanceCommands.AddBalance,
    ...data,
  }),
);

export const addBalance = createAsyncThunk(
  'balances/addBalance',
  (data: AddBalancePayload, { getState, dispatch }) => {
    const accounts = selectLoanBalancesExtended(getState() as RootState);
    const account = accounts.find((acc) => acc.accountID === data.accountID);
    const balances = account?.assets ?? [];

    const extended = balances.map(({ token, volume, volumeUSD }) => {
      const updated = data.balances.find((b) => b.token === token);
      return (
        updated ?? {
          token,
          value: volume,
          usd: volumeUSD,
        }
      );
    });

    const payload = {
      ...data,
      balances: extended,
    };

    dispatch(addBalanceWs(payload));
  },
);

export const createBalancesSnapshot = createWsAction<
  { loanID: number } | { projectID: number },
  { loanID: number } | { projectID: number }
>('balances/addBalanceWs', (data) => ({
  command: BalanceCommands.AddBalance,
  ...data,
}));
