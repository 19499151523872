import React from 'react';
import { Skeleton, Typography } from 'antd';
import type { TextProps } from 'antd/es/typography/Text';

const { Text } = Typography;

export interface SkyLabelProps {
  // text?: string | React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  loading?: boolean;
  children: React.ReactNode;
  ellipsis?: TextProps['ellipsis'];
  copyable?: TextProps['copyable'];
}

export default function SkyLabel({
  className = '',
  icon = undefined,
  loading = false,
  ellipsis = undefined,
  copyable = undefined,
  children,
}: Readonly<SkyLabelProps>) {
  const cls = `font-semibold uppercase tracking-[1px] ${className}`;

  if (loading) {
    return <Skeleton active paragraph={{ rows: 1 }} />;
  }

  if (ellipsis || copyable) {
    return (
      <>
        {icon ?? null}
        <Text ellipsis={ellipsis} copyable={copyable} className={`${cls}`}>
          {children}
        </Text>
      </>
    );
  }

  return (
    <span className={`${cls}`}>
      {icon ?? null}
      {children}
    </span>
  );
}
