import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus } from 'src/store/types';

export enum LoanStatsCommands {
  LoanStats = 'LoanStats',
}

export interface JsonLoanStats {
  pair: string;
  token: string;
  currentPrice: number;
  maxProfit: number; // including funds
  description: string; // hint for maxProfit
  baseCurrentVolume: number; // in token
  baseCurrentVolumeUSD: number; // in usd
  paybackAmountBase: number; // in token
  quoteCurrentVolume: number; // in usd
  paybackAmountQuote: number; // in usd
  company: string;
  age: string;
  started: string;
  ended: string;
}

export type JsonLoanStatsSuccess = {
  command: 'LoanStats';
} & JsonLoanStats;

export type LoanStatsState = {
  requestStatus: RequestStatus;
  error: null | GenericError;
} & JsonLoanStats;
