import React from 'react';
import ReactDOM from 'react-dom/client';
import loadable from '@loadable/component';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { App } from 'antd';

// store
import { store, persistor } from 'src/store';

// Initialize Sentry
import './instrument';

// components
const Theme = loadable(() => import('src/sky-ui-lib/SkyTheme'));
const Intl = loadable(() => import('src/sky-ui-lib/SkyIntl'));
const Notification = loadable(() => import('src/sky-ui-lib/SkyNotificationContext'));
const Router = loadable(() => import('src/router'));

// render
const rootDiv = document.getElementById('root')!;

if (!rootDiv) {
  throw new Error("The element #root wasn't found");
}

ReactDOM.createRoot(rootDiv).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Intl>
          <Theme>
            <Notification>
              <App>
                <Router />
              </App>
            </Notification>
          </Theme>
        </Intl>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
