import { createAsyncThunk } from '@reduxjs/toolkit';

import omit from 'lodash/omit';

import { wsLoansSend } from 'src/store/ws-loans/actions';
import { RetainerCommands } from './types';

export interface ListenPayload {
  section: string; // 'balances' or 'overview';
  isInitCall: boolean;
  projectID?: number;
}

export interface HideProjectPayload {
  projectID?: number;
}

export const listen = createAsyncThunk('retainers/listen', (data: ListenPayload, thunkApi) => {
  thunkApi.dispatch(
    wsLoansSend({
      command: RetainerCommands.Listen,
      ...omit(data, 'isInitCall'),
    }),
  );
});

export const hideProject = createAsyncThunk(
  'retainers/hideProject',
  (data: HideProjectPayload, thunkApi) => {
    thunkApi.dispatch(
      wsLoansSend({
        command: RetainerCommands.HideProject,
        ...data,
      }),
    );
  },
);
