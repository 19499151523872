import type { KpiState } from '../../shared/kpi/types';

interface Store {
  loans: {
    kpi: KpiState;
  };
}

export const selectKpiList = (store: Store) => store.loans.kpi.kpiList;
export const selectSelectedEditKpi = (store: Store) => store.loans.kpi.selectedEditKpi;
export const selectAddKpiStatus = (store: Store) => store.loans.kpi.resAddKpi;
export const selectEditKpiStatus = (store: Store) => store.loans.kpi.resEditKpi;
export const selecteKpiRequestStatus = (store: Store) => store.loans.kpi.requestStatus;
