import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';

import type { LogItem } from 'src/store/shared/types';

import type {
  JsonAccountEnableSuccess,
  JsonAccountDisableSuccess,
  JsonAccount,
  JsonLinechartExchange,
} from 'src/store/shared/accounts/types';

import type { GenericError } from 'src/store/utils/errors';
import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import type { JsonConnection, RequestStatus } from 'src/store/types';
import type { JsonExchange } from 'src/store/shared/exchanges/types';

import { AccountStatus } from 'src/store/shared/accounts/types';
import pickBy from 'lodash/pickBy';
import { listen } from './actions';
import { AdminCommands } from './types';

export interface AccountDetailsPieChartDataEntry {
  name: string;
  percent: number;
  summ: number;
  additional: number;
}

export interface AccountDetailsPieChartData {
  name: string;
  parts: AccountDetailsPieChartDataEntry[];
}

export interface JsonAccountDetails {
  usd: number; // current balance in USD
  accounts: JsonAccount[]; // subaccounts (there should be related info in pairs as well)
  linechartWeek?: JsonLinechartExchange;
  linechartYear?: JsonLinechartExchange;
  status: AccountStatus;
  error: string;
  piechart: AccountDetailsPieChartData;
}

interface JsonGetAccountDetailsResponse {
  command: 'AccountDetails';
  // api: string;
  // takerFee: number;
  // makerFee: number;
  // createDate: IsoDateString;
  // statistic: {
  //   volumeBuy: number;
  //   priceBuy: number;
  //   volumeSell: number;
  //   priceSell: number;
  //   fee: number;
  //   firstTradeDate: IsoDateString;
  //   profit: number;
  // };
  // bot: string;
  // ticker: string;
  // copies: string;
  // proxy: string;
  // project: null; // ???
  // loan: null; // ???
  account: JsonAccountDetails;
}

export interface JsonLoanTermChangesLogResponse {
  command: 'Log';
  list: LogItem[];
}

export interface AdminState {
  error: null | GenericError;

  servers: JsonServer[];
  isServersLoading: boolean;
  accountDetails: JsonAccountDetails | null;
  accountDetailsRequestStatus: RequestStatus;
  loanTermChangesLog: LogItem[];
  loanTermChangesLogLoadingStatus: RequestStatus;
}

const initialState: AdminState = {
  error: null,

  servers: [],
  isServersLoading: false,
  accountDetails: null,
  accountDetailsRequestStatus: null,
  loanTermChangesLog: [],
  loanTermChangesLogLoadingStatus: null,
};

export interface JsonTimer {
  name: string; // 'Monitor.Run1Minute'
  averageLoop: number; // 3.44876791256281
  overtimePercentage: number; // 0.25
}

interface InitialData {
  Version: string;
  Name: string;
  IPAddress: string;
  LastSocketTime: string; // "2023-04-16T06:36:40.5109389Z",
  LastWebSocketTime: string; // "2023-04-16T06:41:44.324337Z",
  StartSocketTime: string; // "0001-01-01T00:00:00",
  StartWebSocketTime: string; // "2023-04-16T06:36:38.5744129Z",
  HimikoDatabase: string;
  HimikoDatabaseConnected: boolean;
  Proxy: string;
  LoadingSeconds: number;
  MonitorPing: number;
  HimikoPing: number;
  NoResponseCount: number;
  ErrorsWithChart: boolean;
  TooMuchRequestsException: string | null;
  NoSymbolException: string | null;
  WrongAPIKeyException: string | null;
  WrongIPException: string | null;
  AvailableTimeframes: any[];
}

interface InitialDataCamelCase {
  version: string;
  name: string;
  ipAddress: string;
  lastSocketTime: string; // "2023-04-16T06:36:40.5109389Z",
  lastWebSocketTime: string; // "2023-04-16T06:41:44.324337Z",
  startSocketTime: string; // "0001-01-01T00:00:00",
  startWebSocketTime: string; // "2023-04-16T06:36:38.5744129Z",
  himikoDatabase: string;
  himikoDatabaseConnected: boolean;
  proxy: string;
  loadingSeconds: number;
  monitorPing: number;
  himikoPing: number;
  noResponseCount: number;
  errorsWithChart: boolean;
  tooMuchRequestsException: string | null;
  noSymbolException: string | null;
  wrongApiKeyException: string | null;
  wrongIpException: string | null;
  availableTimeframes: any[];
}

interface MonitorStatus {
  id: number;
  version: string;
  buildDate: string; // "built 14 April 15:13"
  averageRequestsPerMinute: number;
  tooMuchRequestPerMinute: number;
  monitorErrorsInLog: number;
  himikoErrorsInLog: number;
  errorRequestsPercentage: number;
  errorBalancePercentage: number;
  getSummaryAvailable: boolean;
  loadChartAvailable: boolean;
  getFeeRateAvailable: boolean;
  getTransactionsAvailable: boolean;
  sentTime: string; // "2023-04-16 06:41:29",
  lastUpdateTime: string; // "2023-04-16 06:41:25",
  priceUpdated: boolean;
  balancesUpdated: boolean;
  accounts: JsonConnection[];
  timers: JsonTimer[];
  chartsUpdated: number[]; // [1000, 60]
  bases: string[]; // ['USD', 'BTC' ...]
  blockedTickers: string[]; // ["CCD/BTC/BFNX", "CHEX/USD/BFNX", ...]
  status: string; // "" ?
}

interface JsonServersSuccess {
  command: 'Servers';
  statuses: {
    initialData: InitialData;
    monitorStatus: MonitorStatus;
    description: string;
    lastUpdateTime: string; // "2023-04-16 06:41:25",
  }[];
}

export type JsonServer = {
  description: string;
  lastUpdateTime: string;
} & MonitorStatus &
  InitialDataCamelCase;

export type JsonServerExchange = JsonServer & { exchange?: JsonExchange };

function updateAccountEnableStatus(json: JsonAccountEnableSuccess) {
  const { result } = json;
  try {
    const updated = JSON.parse(result);
    const filteredData = pickBy(updated, (value) => value !== '');

    return (server: JsonServer) => ({
      ...server,
      accounts: server.accounts.map((account) => {
        if (account.name === updated.name) {
          return {
            ...account,
            ...filteredData,
          };
        }
        return account;
      }),
    });
  } catch (err: any) {
    console.error('Failed to parse response data for account status update request', err);
    return (server: JsonServer) => server;
  }
}

function updateAccountDisableStatus(json: JsonAccountDisableSuccess) {
  const { id } = json;
  return (server: JsonServer) => ({
    ...server,
    accounts: server.accounts.map((account) => {
      if (account.name === id) {
        return {
          ...account,
          status: AccountStatus.Disabled,
        };
      }
      return account;
    }),
  });
}

const allowedCommands = Object.values(AdminCommands);

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // listen

    builder.addCase(listen.pending, (state, action: any) => {
      const { isInitCall, section, accountID } = action.meta.arg;
      if (isInitCall) {
        if (accountID) {
          state.accountDetailsRequestStatus = 'pending';
        } else {
          state.isServersLoading = section === 'admin';
          state.loanTermChangesLogLoadingStatus = section === 'log' ? 'pending' : null;
        }
      }
    });

    // on WS message

    builder.addCase(actionOnMessage, (state, action: PayloadAction<any>) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(`admin/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      const { command, okCommand } = json;

      if (error) {
        state.error = error;
        return;
      }

      if (command === 'Servers') {
        const { statuses }: JsonServersSuccess = json;

        state.servers = map(
          statuses,
          (status) =>
            ({
              ...status.monitorStatus,
              ...mapKeys(status.initialData, (value, key) => {
                if (key === 'Version') {
                  return 'himikoVersion';
                }

                return camelCase(key);
              }),
              description: status.description,
              lastUpdateTime: status.lastUpdateTime,
            }) as JsonServer,
        );
      }

      if (command === 'AccountDetails') {
        const { account } = json as JsonGetAccountDetailsResponse;
        state.accountDetails = account;
        state.accountDetailsRequestStatus = 'success';
      }

      if (command === 'Log') {
        const { list } = json as JsonLoanTermChangesLogResponse;
        state.loanTermChangesLog = list;
        state.loanTermChangesLogLoadingStatus = 'success';
      }

      if (command === 'ok') {
        switch (okCommand) {
          case 'EnableAccount':
            state.servers = state.servers.map(updateAccountEnableStatus(json));
            break;
          case 'DisableAccount':
            state.servers = state.servers.map(updateAccountDisableStatus(json));
            break;
          default:
        }
      }
    });
  },
});

export const adminActions = { ...adminSlice.actions, listen };
// export const { toggleSidebar, openNewAdminModal, closeNewAdminModal } = adminSlice.actions;

export default adminSlice.reducer;
