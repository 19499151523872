import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { actionOnMessage } from 'src/store/ws-loans/actions';
import { parseResponse } from 'src/store/utils';

import type { JsonBalance, JsonBalancesSuccess, BalancesState } from './types';

import { BalanceCommands } from './types';

import { addBalance, closeSmallTokens, createBalancesSnapshot } from './actions';

const allowedCommands = Object.values(BalanceCommands);

const initialState: BalancesState = {
  error: null,
  requestStatus: null,

  // Loans balances, data on Balances
  list: [],

  resCloseSmallTokens: null,

  selection: [],

  createSnapshotStatus: null,

  supportedTokens: [],
};

const Balances = createSlice({
  name: 'balances',
  initialState,
  reducers: {
    setSelection(state, action: PayloadAction<JsonBalance['accountID'][]>) {
      state.selection = action.payload;
    },
    resetList(state) {
      state.list = [];
      state.selection = [];
    },
  },
  extraReducers: (builder) => {
    // closeSmallTokens

    builder.addCase(closeSmallTokens, (state, action) => {
      console.log(`${closeSmallTokens.toString()}:`, action);
      state.resCloseSmallTokens = {
        status: 'pending',
      };
    });

    builder.addCase(createBalancesSnapshot, (state) => {
      state.createSnapshotStatus = 'pending';
    });

    // on WS message

    builder.addCase(actionOnMessage, (state, action: any) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(
        `shared/balances/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      if (error) {
        state.error = error;

        switch (errorCommand) {
          case BalanceCommands.CloseSmallTokens:
            state.resCloseSmallTokens = { status: 'error' };
            break;
          default:
        }

        return;
      }

      state.error = null;

      const { command, okCommand } = json;

      if (command === BalanceCommands.Balances) {
        const { balances, supportedTokens }: JsonBalancesSuccess = json;

        if (balances) {
          state.list = balances;
        }

        if (supportedTokens) {
          state.supportedTokens = supportedTokens;
        }
        return;
      }

      if (command === 'ok' && okCommand === BalanceCommands.CloseSmallTokens) {
        state.resCloseSmallTokens = {
          status: 'success',
        };
      }

      if (command === 'ok' && okCommand === BalanceCommands.AddBalance) {
        state.createSnapshotStatus = 'success';
      }
    });
  },
});

export const balancesActions = {
  ...Balances.actions,
  closeSmallTokens,
  addBalance,
  createBalancesSnapshot,
};
// export const { toggleSidebar, openNewLoanModal, closeNewLoanModal } = Balances.actions

export default Balances.reducer;
