import { createSlice } from '@reduxjs/toolkit';

interface RtkQueryErrorState {
  error: null | {
    title: string;
    description?: string;
  };
  status: null;
  message: null;
}

const initialState: RtkQueryErrorState = {
  error: null,
  status: null,
  message: null,
};

const rtkQueryErrorSlice = createSlice({
  name: 'rtkQueryError',
  initialState,
  reducers: {
    setError(state, action) {
      console.log('~ rtkQueryError/setError', action);
      state.error = { title: action.payload.data?.message || action.payload.data?.error };
      state.status = action.payload.status;
    },
  },
});

export const rtkQueryErrorActions = rtkQueryErrorSlice.actions;
export default rtkQueryErrorSlice.reducer;
