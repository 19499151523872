export function getLineHue(idx: number, listLength: number) {
  return idx * (360 / listLength);
}

export interface LineColorOptsType {
  hue: number;
  saturation?: number;
  lightness?: number;
  alpha?: number;
}

export function getLineColor({ hue, saturation, lightness, alpha }: LineColorOptsType) {
  return `hsla(${hue}, ${saturation ?? 75}%, ${lightness ?? 75}%, ${alpha ?? 1})`;
}

export interface LineChartDataEntry {
  id: string;
  data: { x: string; y: number }[];
  label?: string;
  color?: string;
  meta?: Record<string, any>;
}

export type LinearDataType = null | {
  priceData: LineChartDataEntry[];
  linechartData: LineChartDataEntry[];
};

export type LineChartRange = 'Week' | 'Year';

const pieColors = [
  { hue: 269.72, saturation: 74.83, lightness: 28.04 },
  { hue: 206.57, saturation: 32.71, lightness: 58.04 },
  { hue: 207.59, saturation: 52.73, lightness: 32.35 },
  { hue: 234.8, saturation: 60.77, lightness: 59.02 },
  { hue: 205.95, saturation: 48.05, lightness: 54.71 },
  { hue: 205.93, saturation: 58.42, lightness: 60.39 },
  { hue: 206.42, saturation: 42.75, lightness: 50.0 },
  { hue: 205.87, saturation: 48.44, lightness: 55.88 },
  { hue: 207.86, saturation: 45.65, lightness: 36.08 },
  { hue: 205.95, saturation: 48.05, lightness: 54.71 },
  { hue: 206.25, saturation: 57.14, lightness: 67.06 },
];

export function getPieHue(idx: number) {
  return idx % pieColors.length;
}

export function getPieColor(idx: number) {
  const { hue, saturation, lightness } = pieColors[getPieHue(idx)];
  return `hsla(${hue}, ${saturation + 10}%, ${lightness - 10}%, 1)`;
}
