import { createContext } from 'react';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { MessageInstance } from 'antd/es/message/interface';
import type { HookAPI } from 'antd/es/modal/useModal';

const NotificationContext = createContext<{
  notificationApi: NotificationInstance;
  messageApi: MessageInstance;
  modalApi: HookAPI;
}>({
  notificationApi: {} as NotificationInstance,
  messageApi: {} as MessageInstance,
  modalApi: {} as HookAPI,
});

export default NotificationContext;
