import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus } from 'src/store/types';
import type { Depth } from 'src/store/loans/types';

export enum LoansSummaryCommands {
  LoansSummary = 'LoansSummary',
}

export interface LoansSummaryState {
  data: LoansSummaryJson[];
  status: RequestStatus;
  error: GenericError | null;
}

export interface JsonLoansSummarySuccess {
  command: LoansSummaryCommands.LoansSummary;
  loans: LoansSummaryJson[];
}

interface LoansSummaryTotalToken {
  token: string;
  value: number;
  usd: number;
}

export interface LoansSummaryJson {
  LoanID: number;
  Token: string;
  TotalValue: number;
  TotalValue24hChange: number;
  TotalValue7dChange: number;
  MaxProfit: number;
  MaxProfit24hChange: number;
  MaxProfit7dChange: number;
  TokenPrice: number;
  TokenPrice24hChange: number;
  TokenPrice7dChange: number;
  TotalToken: LoansSummaryTotalToken;
  QuoteAmount: number;
  CommittedCapitalUSD: number;
  TradingVolume24h: number;
  TradingVolume24hChange: number;
  TradingVolume7dChange: number;
  Depth: Record<Depth, number>;
  TotalDepthProvided: number;
  ExchangesCovered: number[];
  RemainingTermOfTheLoan: number;
  StartDate: string;
  EndDate: string;
}
