import {
  initBalanceTableOptsSlice,
  initBalanceTableOptsState,
} from 'src/store/shared/balance-table-opts/factory';

const initialState = initBalanceTableOptsState({
  isInitialBalanceUsed: false,
  viewGroupBy: 'exchange',
  isIgnoreOtherTokens: true,
});

const { reducer, actions } = initBalanceTableOptsSlice({
  sliceName: 'retainersBalancesUI',
  initialState,
});

export const balanceTableOptsActions = actions;

export default reducer;
