import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus } from 'src/store/types';

export enum LoanProfitCommands {
  LoanProfit = 'LoanProfit',
}

export type LoanProfit = Record<string, number>;

export type LoanFee = Record<string, number>;

export interface LoanTasks {
  AccountID: number;
  Date: string;
  Depth: number[];
  Fee: number;
  ID: number;
  IsDone: boolean;
  Profit: number;
  TaskID: number;
  Type: number;
}

export interface JsonLoanProfit {
  dlaProfit: LoanProfit;
  dlaTasks: LoanTasks[];
  dlaFee: LoanFee;
  ppProfit: LoanProfit;
  ppTasks: LoanTasks[];
  ppFee: LoanFee;
}

export interface ListProfitOfAccount {
  label: string;
  key: string;
  value: number;
}
export interface ListTasksOfLoan {
  id: number;
  taskId: number;
  isDone: boolean;
  value: number;
}

export type JsonLoanTasksList = Record<string, ListTasksOfLoan[]>;

export type JsonLoanProfitSuccess = {
  command: 'LoanProfit';
} & JsonLoanProfit;

export type LoanProfitState = {
  requestStatus: RequestStatus;
  error: null | GenericError;
} & JsonLoanProfit;
