import type { JsonFailure, JsonSuccess } from 'src/store/types';
import { createAction } from '@reduxjs/toolkit';
import { WsChannel } from 'src/store/middleware/handleWsRequest';
import isFunction from 'lodash/isFunction';
import type {
  AccountLiquidityTree,
  JsonLoanLiquidityExtendedTree,
} from 'src/store/loans/liquidity/types';
import has from 'lodash/has';
import type {
  JsonLoanExchangeExtended,
  MarketVolumesItem,
  TradingVolumeItem,
} from 'src/store/loans/exchanges/types';
import type { GenericError } from 'src/store/utils/errors';
import { onError, onParseError } from 'src/store/utils/errors';

export const parseResponse = (payload: { message: string }, allowedCommands: string[] = []) => {
  const result: {
    json: any;
    error?: GenericError | null;
    errorCommand?: string;
    okCommand?: string;
    skipProcessing: boolean;
  } = {
    json: null,
    // error: null,
    // errorCommand: null,
    // okCommand: null,
    skipProcessing: false,
  };

  let json: any;

  try {
    json = JSON.parse(payload.message);
    // console.debug('JSON.parse:', json);
    const { command } = json;

    if (![...allowedCommands, 'ok', 'error'].includes(command)) {
      result.json = json;
      result.skipProcessing = true;
      return result;
    }

    if (command === 'error') {
      const { errorCommand } = json as JsonFailure;
      if (errorCommand && !allowedCommands.includes(errorCommand)) {
        result.json = json;
        result.skipProcessing = true;
        return result;
      }

      result.json = json;
      result.error = onError(json as JsonFailure);
      result.errorCommand = errorCommand;
      result.skipProcessing = false;
      return result;
    }

    if (command === 'ok') {
      const { okCommand } = json as JsonSuccess;
      if (okCommand && !allowedCommands.includes(okCommand)) {
        result.json = json;
        result.skipProcessing = true;
        return result;
      }

      result.json = json;
      result.okCommand = okCommand;
      result.skipProcessing = false;
      return result;
    }

    result.json = json;
    result.skipProcessing = false;
    return result;
  } catch (err: any) {
    result.json = json;
    result.error = onParseError(err);
    result.skipProcessing = false;
    return result;
  }
};

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export function createWsAction(
  type: string,
  callback: () => { command: string },
): ReturnType<typeof createAction<() => { payload: ReturnType<typeof callback> }, string>>;

export function createWsAction<T, P>(
  type: string,
  callback: (data: T) => { command: string } & P,
): ReturnType<typeof createAction<(data: T) => { payload: ReturnType<typeof callback> }, string>>;

export function createWsAction<T>(
  type: string,
  command: string,
): ReturnType<typeof createAction<(data: T) => { payload: T & { command: string } }, string>>;

export function createWsAction<T>(type: string, callback: any) {
  return createAction(type, (data?: T) => ({
    payload: isFunction(callback)
      ? callback(data)
      : {
          command: callback,
          ...(data && { ...data }),
        },
    meta: {
      ws: WsChannel.Loans,
    },
  }));
}

export const isJsonLoanExchangeExtended = (
  rec: JsonLoanExchangeExtended | AccountLiquidityTree,
): rec is JsonLoanExchangeExtended => has(rec, 'children');

export const isJsonLoanExchangeExtendedTree = (
  rec: JsonLoanLiquidityExtendedTree | AccountLiquidityTree,
): rec is JsonLoanLiquidityExtendedTree => has(rec, 'children');

export const isMarketVolumesItem = (
  volumesArray: TradingVolumeItem | MarketVolumesItem,
): volumesArray is MarketVolumesItem => !has(volumesArray, 'Ticker');
