import type { LiquidityMap } from 'src/store/loans/types';
import dayjs, { type Dayjs } from 'src/utils/formatting/dates';
import flatMap from 'lodash/flatMap';
import find from 'lodash/find';
import entries from 'lodash/entries';
import filter from 'lodash/filter';
import type {
  JsonLoanExchangeExtended,
  MarketVolumesItem,
  TradingVolumeItem,
  TradingVolumesMap,
} from 'src/store/loans/exchanges/types';
import type { JsonLoanLiquidityExtended } from 'src/store/loans/liquidity/types';
import map from 'lodash/map';
import has from 'lodash/has';
import { isMarketVolumesItem } from 'src/store/utils/index';

export function getValuesPerDateRange<DataType>({
  data,
  startDate,
  endDate = dayjs(),
}: {
  data: DataType;
  startDate: string | Dayjs;
  endDate?: string | Dayjs;
}) {
  const dataEntries = entries<DataType[keyof DataType]>(data as any);
  return filter(
    dataEntries,
    ([date]) => dayjs(date).isSameOrAfter(startDate, 'day') && dayjs(date).isBefore(endDate),
  );
}

export function getLiquidityPerDepth({
  liquidity,
  depth,
  startDate,
  endDate,
}: {
  liquidity: LiquidityMap | undefined | null;
  depth: number;
  startDate?: string | Dayjs;
  endDate?: string | Dayjs;
}) {
  if (!liquidity) return 0;

  const oneWeekAgo = dayjs().subtract(7, 'day');

  const liquidityEntries = getValuesPerDateRange<LiquidityMap>({
    data: liquidity,
    startDate: startDate ?? oneWeekAgo,
    endDate: endDate ?? undefined,
  });

  const itemsPerDepth = flatMap(liquidityEntries, ([, stats]) => {
    const foundItem = find(stats, (data) => depth === data?.[0]);
    return foundItem?.[1] ?? 0;
  });

  const avgLiquidityPerDepth =
    itemsPerDepth.reduce((acc, value) => acc + value, 0) / itemsPerDepth.length;

  return avgLiquidityPerDepth || 0;
}

export function getLiquidityDetailsByDepth({
  liquidity,
  depth,
}: {
  liquidity: LiquidityMap;
  depth: number;
}) {
  const liquidityDates = Array.from(
    new Set(Object.keys(liquidity).map((date) => dayjs(date).startOf('day').format('YYYY-MM-DD'))),
  );

  return liquidityDates.map((date) => {
    const startDate = dayjs(date).startOf('day');
    const endDate = startDate.add(1, 'day');

    const avg = getLiquidityPerDepth({
      liquidity,
      depth,
      startDate,
      endDate,
    });

    return [startDate.format('YYYY-MM-DD'), avg];
  });
}

export function getTradingVolumesMap(
  volumesArray: TradingVolumeItem[] | MarketVolumesItem[],
  pair?: string,
): TradingVolumesMap {
  return volumesArray.reduce((acc, item) => {
    if (!isMarketVolumesItem(item)) {
      if (pair && item.Ticker.includes(pair)) {
        acc[item.Date] = (acc[item.Date] ?? 0) + item.Volume;
      }
    } else {
      acc[item.Date] = (acc[item.Date] ?? 0) + item.Volume;
    }
    return acc;
  }, {} as TradingVolumesMap);
}

export function getCurrentLiquidityByDepth({
  liquidity,
  depth,
}: {
  liquidity: LiquidityMap;
  depth: number;
}): number {
  const latestLiquidityRecord = Object.entries(liquidity).sort(
    (a, z) => new Date(z[0]).getTime() - new Date(a[0]).getTime(),
  )[0]?.[1];

  return latestLiquidityRecord?.find(([currentDepth]) => currentDepth === depth)?.[1] ?? 0;
}

export function getLiquidityTreeData<T>(
  data: (JsonLoanLiquidityExtended | JsonLoanExchangeExtended)[],
): T[] {
  const treeData = map(data, (record) => ({
    ...record,
    key: `${record.exchangeID}__${record.pair}`,
    // if record has liquidityByAccount then it is JsonLoanLiquidityExtended
    children: has(record, 'liquidityByAccount')
      ? map((record as JsonLoanLiquidityExtended).liquidityByAccount, (account) => ({
          ...account,
          key: `${record.exchangeID}__${record.pair}__${account.accountId}`,
          exchange: { ...record.exchange },
          avgMarketLiquidityPlus1: record.avgMarketLiquidityPlus1,
          avgMarketLiquidityPlus2: record.avgMarketLiquidityPlus2,
          avgMarketLiquidityMinus1: record.avgMarketLiquidityMinus1,
          avgMarketLiquidityMinus2: record.avgMarketLiquidityMinus2,
          kpi: record.kpi,
        }))
      : [],
  })) as T[];
  return treeData;
}

export const LIQUIDITY_MISMATCH_THRESHOLD = 2; // USD
