import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';
import type { RootState } from 'src/store';
import { connect, disconnect, send } from '../middleware/redux-websocket';

import { prefix } from './middleware';

export const actionConnect = createAction<object>(`${prefix}::CONNECT`);
export const actionOnOpen = createAction<object>(`${prefix}::OPEN`);
export const actionDisconnect = createAction<object>(`${prefix}::DISCONNECT`);
export const actionOnClose = createAction<object>(`${prefix}::CLOSE`);
export const actionOnClosed = createAction<object>(`${prefix}::CLOSED`);
export const actionSend = createAction<object>(`${prefix}::SEND`);
export const actionOnMessage = createAction<{ message: string }>(`${prefix}::MESSAGE`);
export const actionOnBroken = createAction<object>(`${prefix}::BROKEN`);
export const actionOnReconnectBegin = createAction<object>(`${prefix}::BEGIN_RECONNECT`);
export const actionOnReconnectAttempt = createAction<object>(`${prefix}::RECONNECT_ATTEMPT`);
export const actionOnReconnected = createAction<object>(`${prefix}::RECONNECTED`);
export const actionOnError = createAction<object>(`${prefix}::ERROR`);

export const wsLoansConnect = () => {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const ssWsKey = import.meta.env.VITE_LOANS_WS_SS_KEY || 'WS_URL_OVERRIDE';
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const qsWsKey = import.meta.env.VITE_LOANS_WS_QS_KEY || 'ws';
  const query = qs.parse(document.location.search, { ignoreQueryPrefix: true });

  const qsVal = query[qsWsKey]?.toString();

  if (qsVal && import.meta.env.VITE_ALLOW_WS_OVERRIDE === '1') {
    if (qsVal !== '0') {
      sessionStorage.setItem(ssWsKey, qsVal);

      console.log(`WS URL has been replaced with query param: ${qsVal}`);
      return connect(qsVal, prefix);
    }

    sessionStorage.removeItem(ssWsKey);
  }

  const storedWs = sessionStorage.getItem(ssWsKey);
  if (storedWs && import.meta.env.VITE_ALLOW_WS_OVERRIDE === '1') {
    return connect(storedWs, prefix);
  }

  return connect(import.meta.env.VITE_LOANS_WS_URL, prefix);
};

export const wsLoansDisconnect = () => disconnect(prefix);

export const wsLoansSend = (message: any) => send(message, prefix);

export const subscribeWsData = createAsyncThunk('ws/connect', (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const wsStatus = state.wsLoans.status;

  if (wsStatus === 'idle') {
    thunkAPI.dispatch(wsLoansConnect());
  }
});
