import { createSelector } from '@reduxjs/toolkit';

import type { JsonConnection } from 'src/store/types';
import type { RootState } from 'src/store';

export const selectError = (store: RootState) => store.accounts.error;

export const selectIsAccountsLoading = (store: RootState) =>
  store.accounts.requestStatus === 'pending';

export const selectAccountStats = (store: RootState) => store.accounts.stats;
export const selectAccounts = (store: RootState) => store.accounts.list;

export const selectObjAccounts = createSelector(selectAccounts, (list) =>
  list.reduce((acc, val) => ({ ...acc, [val.name]: val }), {}),
);

export const selectBrokenAccounts = (store: RootState) => store.accounts.brokenAccounts;

export const selectIsAccountUpdating = (store: RootState) =>
  store.accounts.resUpdateAccount?.status === 'pending';
export const selectIsAccountUpdated = (store: RootState) =>
  store.accounts.resUpdateAccount?.status === 'success';
export const selectIsAccountUpdateStatus = (store: RootState) => store.accounts.resUpdateAccount;

export const selectJoinedAccounts = createSelector(
  selectAccounts,
  selectBrokenAccounts,
  (accounts: JsonConnection[], brokenAccounts: JsonConnection[]) => accounts.concat(brokenAccounts),
);

export const selectTokensByAccounts = createSelector(selectAccountStats, (stats = []) =>
  stats.reduce(
    (acc, { accounts }) => {
      accounts.forEach(({ id, ticker }) => {
        const [token1, token2] = (ticker || '').split('/');
        acc[id] = [token1, token2];
      });
      return acc;
    },
    {} as Record<string, [string, string]>,
  ),
);
