import { createSelector } from '@reduxjs/toolkit';

import {
  getExtendedBalancesHistory,
  getFilteredTokens,
  getLinearData,
  getNewExtendedBalances,
  getTransactionSummary,
  getUniqueBalanceTokens,
  getUniqueCompareTokens,
} from 'src/store/utils/balances';
import {
  selectBalanceHistory,
  selectComparisonEndDate,
  selectComparisonStartDate,
  selectPriceHistory,
} from 'src/store/shared/balance-history/selectors';
import { selectExchanges } from 'src/store/shared/exchanges/selectors';

import {
  selectBalancesList,
  selectBalancesSelection,
  selectBalancesSupportedTokens,
} from 'src/store/shared/balances/selectors';
import { selectJoinedAccounts } from 'src/store/shared/accounts/selectors';
import { selectTransactions } from 'src/store/shared/transactions/selectors';

import {
  selectIgnoreTokens,
  selectIsDuplicatesVisible,
  selectIsIgnoreOtherTokens,
  selectIsInitialBalanceUsed,
  selectIsSmallBalancesVisible,
  selectShowDlaBots,
} from 'src/store/loans/balance-table-opts/selectors';
import { selectColdWallets, selectWalletsSnapshots } from 'src/store/loans/cold-wallets/selectors';
import type { JsonConnection } from 'src/store/types';
import type {
  JsonBalance,
  JsonBalanceExtended,
  JsonBalanceHistoryExtended,
} from 'src/store/shared/balances/types';
import type { JsonExchange } from 'src/store/shared/exchanges/types';
import type {
  JsonBalanceHistoryItem,
  UniqueTokensList,
} from 'src/store/shared/balance-history/types';
import type { JsonTransaction } from 'src/store/shared/transactions/types';
import { selectViewTarget } from 'src/store/loans/balance-table-opts/selectors';
import { ApplicationDomain } from 'src/sky-router';
// import type { WalletData } from 'src/store/cold-wallets/types';

export const selectLoanBalancesTokens = createSelector(
  selectBalancesList,
  selectIsIgnoreOtherTokens,
  selectBalancesSupportedTokens,
  (
    balances: JsonBalance[] | undefined,
    isIgnoreOtherTokens: boolean | undefined,
    supportedTokens: UniqueTokensList[] | undefined = [],
  ) =>
    getUniqueBalanceTokens({
      data: balances,
      isIgnoreOtherTokens,
      supportedTokens,
    }),
);

const selectUniqToken = createSelector(
  selectLoanBalancesTokens,
  selectIgnoreTokens,
  selectIsSmallBalancesVisible,
  (tokens, ignoreTokens, isSmallBalancesVisible) =>
    getFilteredTokens({ tokens, ignoreTokens, isSmallBalancesVisible }),
);

// balances list with exchange and account details populated

export const selectLoanBalancesExtended = createSelector(
  selectIsSmallBalancesVisible,
  selectIsDuplicatesVisible,
  selectBalancesList,
  selectColdWallets,
  selectExchanges,
  selectJoinedAccounts,
  selectShowDlaBots,
  selectUniqToken,
  (
    isSmallBalancesVisible: boolean | undefined,
    isDuplicatesVisible: boolean | undefined,
    balances: JsonBalance[] | undefined,
    coldWallets: JsonBalance[] | undefined,
    exchanges: JsonExchange[],
    allAccounts: JsonConnection[],
    showDlaBots: boolean | undefined,
    selectedTokens: UniqueTokensList[],
  ): JsonBalanceExtended[] =>
    getNewExtendedBalances({
      domain: ApplicationDomain.Loans,
      isSmallBalancesVisible,
      isDuplicatesVisible,
      balances,
      coldWallets,
      exchanges,
      accounts: allAccounts,
      showDlaBots,
      selectedTokens,
    }),
);

export const selectLoanBalancesHistoryExtended = createSelector(
  selectIsSmallBalancesVisible,
  selectIsDuplicatesVisible,
  selectIsInitialBalanceUsed,
  selectComparisonStartDate,
  selectComparisonEndDate,
  selectBalancesList,
  selectBalanceHistory,
  selectColdWallets,
  selectWalletsSnapshots,
  selectExchanges,
  selectJoinedAccounts,
  selectTransactions,
  selectShowDlaBots,
  (
    isSmallBalancesVisible: boolean | undefined,
    isDuplicatesVisible: boolean | undefined,
    isInitialBalanceUsed: boolean | undefined,
    comparisonStartDate: string | undefined,
    comparisonEndDate: string | undefined,
    balances: JsonBalance[] | undefined,
    history: JsonBalanceHistoryItem[] | undefined,
    coldWallets: JsonBalance[] | undefined,
    coldWalletsSnapshots: JsonBalanceHistoryItem[] | undefined,
    exchanges: JsonExchange[],
    allAccounts: JsonConnection[],
    transactions: JsonTransaction[],
    showDlaBots: boolean | undefined,
  ): JsonBalanceHistoryExtended[] =>
    getExtendedBalancesHistory({
      domain: ApplicationDomain.Loans,
      isSmallBalancesVisible,
      isDuplicatesVisible,
      isInitialBalanceUsed,
      comparisonStartDate,
      comparisonEndDate,
      balances,
      history,
      coldWallets,
      coldWalletsSnapshots,
      exchanges,
      accounts: allAccounts,
      transactions,
      showDlaBots,
    }),
);

export const selectLinearData = createSelector(
  selectLoanBalancesHistoryExtended,
  selectPriceHistory,
  selectBalancesSelection,
  selectComparisonStartDate,
  selectComparisonEndDate,
  selectViewTarget,
  (data, priceHistory, selection, startDate, endDate, viewTarget) =>
    getLinearData(data, priceHistory, selection, viewTarget, startDate, endDate),
);

export const selectLoanCompareTokens = createSelector(
  selectLoanBalancesHistoryExtended,
  selectIsIgnoreOtherTokens,
  (history: JsonBalanceHistoryExtended[] | undefined, isIgnoreOther: boolean) =>
    getUniqueCompareTokens({
      data: history,
      isIgnoreOther,
    }),
);

export const selectLoanBalancesHistoryExtendedWithoutTransaction = createSelector(
  selectIsSmallBalancesVisible,
  selectIsDuplicatesVisible,
  selectIsInitialBalanceUsed,
  selectComparisonStartDate,
  selectComparisonEndDate,
  selectBalancesList,
  selectBalanceHistory,
  selectColdWallets,
  selectWalletsSnapshots,
  selectExchanges,
  selectJoinedAccounts,
  selectShowDlaBots,
  (
    isSmallBalancesVisible: boolean | undefined,
    isDuplicatesVisible: boolean | undefined,
    isInitialBalanceUsed: boolean | undefined,
    comparisonStartDate: string | undefined,
    comparisonEndDate: string | undefined,
    balances: JsonBalance[] | undefined,
    history: JsonBalanceHistoryItem[] | undefined,
    coldWallets: JsonBalance[] | undefined,
    coldWalletsSnapshots: JsonBalanceHistoryItem[] | undefined,
    exchanges: JsonExchange[],
    allAccounts: JsonConnection[],
    showDlaBots: boolean | undefined,
  ): JsonBalanceHistoryExtended[] =>
    getExtendedBalancesHistory({
      domain: ApplicationDomain.Loans,
      isSmallBalancesVisible,
      isDuplicatesVisible,
      isInitialBalanceUsed,
      comparisonStartDate,
      comparisonEndDate,
      balances,
      history,
      coldWallets,
      coldWalletsSnapshots,
      exchanges,
      accounts: allAccounts,
      showDlaBots,
      transactions: [],
    }),
);

export const selectLoanTransactionSummary = createSelector(
  selectTransactions,
  selectComparisonStartDate,
  selectComparisonEndDate,
  getTransactionSummary,
);
