import React from 'react';
import isString from 'lodash/isString';
import type { ButtonProps } from 'antd/lib';
import { Button, Tooltip } from 'antd';
import type { SkyLabelProps } from 'src/sky-ui-lib/SkyLabel';
import SkyLabel from 'src/sky-ui-lib/SkyLabel';

const disabledButton = 'transition-opacity duration-150 ease-in-out opacity-50 cursor-not-allowed';

const typeClsMap = {
  primary: 'bg-slate-100 text-slate-900 hover:bg-sky-700 hover:text-white',
  primaryDisabled: `bg-slate-100 text-slate-900 ${disabledButton}`,
  dark: 'bg-stone-900/80 text-white hover:bg-sky-700 hover:text-white',
  darkDisabled: `bg-stone-900/80 text-white ${disabledButton}`,
  default: 'shadow-none hover:bg-sky-800 hover:text-white',
  defaultDisabled: `shadow-none ${disabledButton}`,
  link: 'text-blue-500 hover:text-blue-700',
  linkDisabled: `text-blue-500 ${disabledButton}`,
};

const dangerClsMap = {
  primary: 'bg-red-500 text-white hover:bg-red-800 hover:text-white',
  primaryDisabled: `bg-red-500 text-white ${disabledButton}`,
  dark: 'bg-red-500 text-white hover:bg-red-800 hover:text-white',
  darkDisabled: `bg-red-500 text-white ${disabledButton}`,
  default:
    'shadow-none border-red-500 hover:bg-red-800 hover:text-white text-red-300 hover:text-red-100/80',
  defaultDisabled: `shadow-none ${disabledButton}`,
  link: 'text-red-500 hover:text-red-700',
  linkDisabled: `text-red-500 ${disabledButton}`,
};

const sizeClsMap = {
  xsmall: 'px-0 xy-0 h-4 text-sm',
  small: 'text-sm',
  middle: 'h-9',
  large: 'h-15',
};

interface SkyButtonProps {
  type?: 'default' | 'primary' | 'link' | 'text' | 'dashed' | 'dark';
  size?: 'large' | 'middle' | 'small' | 'xsmall';
  shape?: 'default' | 'circle' | 'round';
  disabled?: boolean;
  className?: string;
  truncate?: number;
  tooltip?: string;
  block?: boolean;
  danger?: boolean;
  href?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  labelOptions?: Omit<SkyLabelProps, 'children'>;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  onClick?: ButtonProps['onClick'];
  loading?: ButtonProps['loading'];
}

function SkyButtonFn<Props extends SkyButtonProps>(
  {
    type = 'default',
    size = 'middle',
    disabled = false,
    className = '',
    children,
    htmlType = undefined,
    truncate,
    tooltip,
    block = false,
    danger = false,
    labelOptions = undefined,
    ...rest
  }: Props,
  ref: any,
) {
  const typeKey = disabled ? `${type}Disabled` : type;
  const typeCls = danger
    ? dangerClsMap[typeKey as keyof typeof dangerClsMap]
    : typeClsMap[typeKey as keyof typeof typeClsMap];
  const sizeCls = sizeClsMap[size];

  const buttonOpts = {
    type: type === 'dark' ? 'text' : type,
    size: size === 'xsmall' ? 'small' : size,
    htmlType,
    className: `flex items-center justify-center transition-colors duration-300 ease-linear ${typeCls} ${sizeCls} ${className}`,
    disabled,
    block,
    ...rest,
  };

  const labelOpts = labelOptions ?? {};

  if (truncate && isString(children) && children.length > truncate) {
    return (
      <Tooltip title={children}>
        <Button {...buttonOpts} ref={ref}>
          <SkyLabel {...labelOpts}>{children.slice(0, truncate)}...</SkyLabel>
        </Button>
      </Tooltip>
    );
  }

  if (tooltip) {
    return (
      <Tooltip title={tooltip} trigger={['click', 'hover']}>
        <Button {...buttonOpts} ref={ref}>
          {children && <SkyLabel {...labelOpts}>{children}</SkyLabel>}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button {...buttonOpts} ref={ref}>
      {children && <SkyLabel {...labelOpts}>{children}</SkyLabel>}
    </Button>
  );
}

export default React.forwardRef(SkyButtonFn);
