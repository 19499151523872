import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  BalanceViewGroupBy,
  BalanceViewMode,
  BalanceViewTarget,
  BalanceViewRounding,
} from 'src/components/BalancesTable/types';
import type { SliceFactoryProps } from 'src/store/shared/types';
import type { BalanceTableOptsState } from './types';

export function initBalanceTableOptsState(opts?: Partial<BalanceTableOptsState>) {
  const initialState: BalanceTableOptsState = {
    isSmallBalancesVisible: false,
    isDuplicatesVisible: false,
    isInitialBalanceUsed: false,
    viewMode: 'table',
    viewGroupBy: 'type',
    viewTarget: 'volume',
    viewRounding: 'on',
    showDlaBots: true,
    ignoreDlaBots: false,
    isIgnoreOtherTokens: false,
    ignoreTokens: [],
  };

  return { ...initialState, ...opts };
}

export function initBalanceTableOptsSlice({
  sliceName = 'balanceTableOpts',
  initialState = initBalanceTableOptsState(),
}: SliceFactoryProps<BalanceTableOptsState, never>) {
  const balanceTableOptsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      setInitialState(state, action: PayloadAction<Partial<BalanceTableOptsState>>) {
        state.viewMode = action.payload.viewMode ?? initialState.viewMode;
        state.viewGroupBy = action.payload.viewGroupBy ?? initialState.viewGroupBy;
        state.viewTarget = action.payload.viewTarget ?? initialState.viewTarget;
        state.viewRounding = action.payload.viewRounding ?? initialState.viewRounding;
      },
      toggleSmallBalances(state) {
        state.isSmallBalancesVisible = !state.isSmallBalancesVisible;
        state.ignoreTokens = [];
      },
      toggleDuplicates(state) {
        state.isDuplicatesVisible = !state.isDuplicatesVisible;
      },
      toggleInitialBalanceUsed(state) {
        state.isInitialBalanceUsed = !state.isInitialBalanceUsed;
      },
      toggleViewGroupBy(state, action: PayloadAction<BalanceViewGroupBy>) {
        state.viewGroupBy = action.payload;
      },
      toggleViewMode(state, action: PayloadAction<BalanceViewMode>) {
        state.viewMode = action.payload;
      },
      toggleViewTarget(state, action: PayloadAction<BalanceViewTarget>) {
        state.viewTarget = action.payload;
      },
      toggleViewRounding(state, action: PayloadAction<BalanceViewRounding>) {
        state.viewRounding = action.payload;
      },
      toggleShowDlaBots(state) {
        state.showDlaBots = !state.showDlaBots;
      },
      toggleIgnoreDlaBots(state) {
        state.ignoreDlaBots = !state.ignoreDlaBots;
      },
      toggleIsIgnoreOtherTokens(state) {
        state.isIgnoreOtherTokens = !state.isIgnoreOtherTokens;
      },
      toggleIgnoreTokens(state, action: PayloadAction<string>) {
        if (state.ignoreTokens.includes(action.payload)) {
          state.ignoreTokens = state.ignoreTokens.filter((token) => token !== action.payload);
        } else {
          state.ignoreTokens = [...state.ignoreTokens, action.payload];
        }
      },
      resetIgnoreTokens(state) {
        state.ignoreTokens = [];
      },
    },
  });

  const actions = {
    ...balanceTableOptsSlice.actions,
  };

  return {
    actions,
    reducer: balanceTableOptsSlice.reducer,
  };
}
