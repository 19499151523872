import { createSelector } from '@reduxjs/toolkit';

import find from 'lodash/find';
import map from 'lodash/map';
import filter from 'lodash/filter';

import dayjs from 'src/utils/formatting/dates';

import { selectExchanges } from 'src/store/shared/exchanges/selectors';

import { selectKpiList } from 'src/store/loans/kpi/selectors';
import {
  getCurrentLiquidityByDepth,
  getLiquidityDetailsByDepth,
  getLiquidityPerDepth,
  getTradingVolumesMap,
  getValuesPerDateRange,
} from 'src/store/utils/liquidity';
import sumBy from 'lodash/sumBy';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';
import forEach from 'lodash/forEach';
import has from 'lodash/has';
import type {
  ActualDepth,
  JsonLoanExchange,
  JsonLoanExchangeExtended,
  LoanExchangesState,
  MarketVolumesItem,
  TradingVolumeItem,
  TradingVolumesMap,
} from './types';

interface Store {
  loans: {
    exchanges: LoanExchangesState;
  };
}

export const selectIsLoanExchangesLoading = (store: Store) =>
  store.loans.exchanges.requestStatus === 'pending';

const selectLoanExchanges = (store: Store) => store.loans.exchanges.list;

export const selectLoanSupportedExchanges = (store: Store) =>
  store.loans.exchanges.supportedExchanges;

export const selectLoanSupportedPairs = (store: Store) => store.loans.exchanges.supportedPairs;

export function getTradingVolumeData(tradingVolumes: TradingVolumeItem[], pair?: string) {
  const oneWeekAgo = dayjs().subtract(7, 'day');
  const twoWeeksAgo = dayjs().subtract(14, 'day');
  const yesterday = dayjs().subtract(1, 'day');
  const tradingVolumesMap = getTradingVolumesMap(tradingVolumes, pair);

  const tradingVolumeLast = getValuesPerDateRange<TradingVolumesMap>({
    data: tradingVolumesMap,
    startDate: oneWeekAgo,
    endDate: yesterday,
  }).reduce((acc, [, value]) => acc + (value ?? 0), 0);

  const tradingVolumePrev = getValuesPerDateRange<TradingVolumesMap>({
    data: tradingVolumesMap,
    startDate: twoWeeksAgo,
    endDate: oneWeekAgo,
  }).reduce((acc, [, value]) => acc + (value ?? 0), 0);

  return {
    tradingVolumeLast: tradingVolumeLast ?? 0,
    tradingVolumePrev: tradingVolumePrev ?? 0,
    tradingVolumeDiff:
      tradingVolumeLast && tradingVolumePrev ? tradingVolumeLast / tradingVolumePrev - 1 : 0,
  };
}

export function getTradingVolumeByDate(
  tradingVolumes: TradingVolumeItem[] | MarketVolumesItem[],
  pair?: string,
): [string, number][] {
  const oneWeekAgo = dayjs().subtract(7, 'day');
  const tradingVolumesMap = getTradingVolumesMap(tradingVolumes, pair);
  const tradingVolumeLast = getValuesPerDateRange<TradingVolumesMap>({
    data: tradingVolumesMap,
    startDate: oneWeekAgo,
  });

  const tradingVolumeDates = Array.from(
    new Set(
      Object.values(tradingVolumeLast).map((volumes) =>
        dayjs(volumes[0]).startOf('day').format('YYYY-MM-DD'),
      ),
    ),
  );

  if (pair) {
    return Array.from({ length: 8 }, (_, i) => {
      const date = dayjs().subtract(i, 'day').startOf('day').format('YYYY-MM-DD');
      const volume = tradingVolumeDates.includes(date)
        ? tradingVolumeLast.reduce(
            (acc, item) =>
              dayjs(item[0]).startOf('day').format('YYYY-MM-DD') === date ? acc + item[1] : acc,
            0,
          )
        : -2;
      return [date, volume] as [string, number];
    }).reverse();
  }

  return tradingVolumeDates.map((date) => {
    const startDate = dayjs(date).startOf('day');
    const endDate = startDate.add(1, 'day');

    const sum = tradingVolumeLast.reduce(
      (acc, item) =>
        dayjs(item[0]).isSameOrAfter(startDate) && dayjs(item[0]).isBefore(endDate)
          ? acc + item[1]
          : acc,
      0,
    );

    return [startDate.format('YYYY-MM-DD'), sum];
  });
}

function getVolumeShareByDate({
  tradingVolumeByDate,
  marketVolumeByDate,
}: {
  tradingVolumeByDate: [string, number][];
  marketVolumeByDate: [string, number][];
}) {
  const uniqueDates = uniqBy(union(marketVolumeByDate, tradingVolumeByDate), 0);
  const sortedUniqueDates =
    uniqueDates?.sort(([a], [z]) => new Date(z).getTime() - new Date(a).getTime()) ?? [];

  return map(sortedUniqueDates, ([date]) => {
    const marketValue = find(marketVolumeByDate, (pair) => pair[0] === date)?.[1];
    const tradingValue = find(tradingVolumeByDate, (pair) => pair[0] === date)?.[1];
    if (!tradingValue) {
      return [date, 0];
    }
    if (tradingValue === -2) {
      return [date, -2];
    }
    if (!marketValue) {
      return [date, -1];
    }
    const share = (tradingValue / marketValue) * 100;
    return [date, share];
  });
}

export const selectLoanTickers = createSelector(
  selectLoanExchanges,
  selectExchanges,
  (loanExchanges, exchanges) =>
    loanExchanges
      .map((entry) => {
        const exchange = find(exchanges, { id: entry.exchangeID });
        if (!exchange) {
          return '';
        }
        return `${entry.pair.toUpperCase()}/${exchange.ticker.toUpperCase()}`;
      })
      .filter(Boolean),
);

export const selectMissingPairs = createSelector(
  selectLoanExchanges,
  selectLoanSupportedPairs,
  (list, supportedPairs) => {
    if (!list.length) {
      return [];
    }

    const loanPairs = supportedPairs ?? [];

    const filteredLoanExchanges = list.filter((item) => !loanPairs.includes(item.pair));

    const missingPairs = [...new Set(map(filteredLoanExchanges, (item) => item.pair))];

    return missingPairs;
  },
);

export const selectLoanExchangesExtended = createSelector(
  selectLoanExchanges,
  selectExchanges,
  selectKpiList,
  selectLoanSupportedExchanges,
  selectLoanSupportedPairs,
  (list, exchanges, kpiList, supportedExchanges, supportedPairs) => {
    if (!list.length || !exchanges.length) {
      return [] as JsonLoanExchangeExtended[];
    }

    const loanExchanges = supportedExchanges ?? [];
    const loanPairs = supportedPairs ?? [];

    let filteredList =
      loanExchanges.length === 0
        ? list
        : list.filter((item) => loanExchanges.includes(item.exchangeID));

    filteredList =
      loanPairs.length === 0
        ? filteredList
        : filteredList.filter((item) => loanPairs.includes(item.pair));

    return map(filteredList, (item: JsonLoanExchange) => {
      const exchange = find(exchanges, { id: item.exchangeID });
      const kpi = filter(kpiList, { ExchangeID: item.exchangeID, Ticker: item.pair }) ?? null;

      const { tradingVolumeLast, tradingVolumePrev, tradingVolumeDiff } = getTradingVolumeData(
        item.tradingVolumes,
        item.pair,
      );
      const tradingVolumeByDate = getTradingVolumeByDate(item.tradingVolumes, item.pair);
      const marketVolumeByDate = getTradingVolumeByDate(item.marketVolumes);
      const volumeShareByDate = getVolumeShareByDate({
        tradingVolumeByDate,
        marketVolumeByDate,
      });

      const marketVolume = sumBy(item.marketVolumes, 'Volume') || 0;
      const volumeShare = marketVolume !== 0 ? (tradingVolumeLast / marketVolume) * 100 : undefined;

      const depths = { actualLiquidity: {} } as JsonLoanExchange;

      forEach(item.actualLiquidity, (value, key) => {
        const numKey = key as ActualDepth;
        if (!has(depths, numKey)) {
          depths.actualLiquidity[numKey] = 0;
        }
        depths.actualLiquidity[numKey] += value;
      });

      const avgProjectLiquidityPlus1 = getLiquidityPerDepth({
        liquidity: item.ourLiquidity,
        depth: +1,
      });
      const avgProjectLiquidityMinus1 = getLiquidityPerDepth({
        liquidity: item.ourLiquidity,
        depth: -1,
      });

      const avgProjectLiquidityPlus2 = getLiquidityPerDepth({
        liquidity: item.ourLiquidity,
        depth: +2,
      });
      const avgProjectLiquidityMinus2 = getLiquidityPerDepth({
        liquidity: item.ourLiquidity,
        depth: -2,
      });

      const avgProjectLiquidityPlus1ByDate = getLiquidityDetailsByDepth({
        liquidity: item.ourLiquidity,
        depth: +1,
      });

      const avgProjectLiquidityPlus2ByDate = getLiquidityDetailsByDepth({
        liquidity: item.ourLiquidity,
        depth: +2,
      });

      const avgProjectLiquidityMinus1ByDate = getLiquidityDetailsByDepth({
        liquidity: item.ourLiquidity,
        depth: -1,
      });

      const avgProjectLiquidityMinus2ByDate = getLiquidityDetailsByDepth({
        liquidity: item.ourLiquidity,
        depth: -2,
      });

      const avgMarketLiquidityPlus1ByDate = getLiquidityDetailsByDepth({
        liquidity: item.marketLiquidity,
        depth: +1,
      });

      const avgMarketLiquidityPlus2ByDate = getLiquidityDetailsByDepth({
        liquidity: item.marketLiquidity,
        depth: +2,
      });

      const avgMarketLiquidityMinus1ByDate = getLiquidityDetailsByDepth({
        liquidity: item.marketLiquidity,
        depth: -1,
      });

      const avgMarketLiquidityMinus2ByDate = getLiquidityDetailsByDepth({
        liquidity: item.marketLiquidity,
        depth: -2,
      });

      const avgMarketLiquidityPlus1 = getLiquidityPerDepth({
        liquidity: item.marketLiquidity,
        depth: +1,
      });

      const avgMarketLiquidityMinus1 = getLiquidityPerDepth({
        liquidity: item.marketLiquidity,
        depth: -1,
      });

      const avgMarketLiquidityPlus2 = getLiquidityPerDepth({
        liquidity: item.marketLiquidity,
        depth: +2,
      });

      const avgMarketLiquidityMinus2 = getLiquidityPerDepth({
        liquidity: item.marketLiquidity,
        depth: -2,
      });

      const currentProjectLiquidityPlus1 = getCurrentLiquidityByDepth({
        liquidity: item.ourLiquidity,
        depth: 1,
      });

      const currentProjectLiquidityPlus2 = getCurrentLiquidityByDepth({
        liquidity: item.ourLiquidity,
        depth: 2,
      });

      const currentProjectLiquidityMinus1 = getCurrentLiquidityByDepth({
        liquidity: item.ourLiquidity,
        depth: -1,
      });

      const currentProjectLiquidityMinus2 = getCurrentLiquidityByDepth({
        liquidity: item.ourLiquidity,
        depth: -2,
      });

      const currentMarketLiquidityPlus1 = getCurrentLiquidityByDepth({
        liquidity: item.marketLiquidity,
        depth: 1,
      });

      const currentMarketLiquidityPlus2 = getCurrentLiquidityByDepth({
        liquidity: item.marketLiquidity,
        depth: 2,
      });

      const currentMarketLiquidityMinus1 = getCurrentLiquidityByDepth({
        liquidity: item.marketLiquidity,
        depth: -1,
      });

      const currentMarketLiquidityMinus2 = getCurrentLiquidityByDepth({
        liquidity: item.marketLiquidity,
        depth: -2,
      });

      return {
        ...item,
        exchange,
        kpi,

        tradingVolumeLast,
        tradingVolumePrev,
        tradingVolumeDiff,
        tradingVolumeByDate,
        marketVolume,
        marketVolumeByDate,
        volumeShare,
        volumeShareByDate,

        avgProjectLiquidityPlus1,
        avgProjectLiquidityMinus1,
        avgProjectLiquidityPlus2,
        avgProjectLiquidityMinus2,

        avgProjectLiquidityPlus1ByDate,
        avgProjectLiquidityPlus2ByDate,
        avgProjectLiquidityMinus1ByDate,
        avgProjectLiquidityMinus2ByDate,

        avgMarketLiquidityPlus1,
        avgMarketLiquidityMinus1,
        avgMarketLiquidityPlus2,
        avgMarketLiquidityMinus2,

        avgMarketLiquidityPlus1ByDate,
        avgMarketLiquidityPlus2ByDate,
        avgMarketLiquidityMinus1ByDate,
        avgMarketLiquidityMinus2ByDate,

        currentProjectLiquidityPlus1,
        currentProjectLiquidityPlus2,
        currentProjectLiquidityMinus1,
        currentProjectLiquidityMinus2,

        currentMarketLiquidityPlus1,
        currentMarketLiquidityPlus2,
        currentMarketLiquidityMinus1,
        currentMarketLiquidityMinus2,

        actualLiquidityPlus1: depths.actualLiquidity['1'],
        actualLiquidityPlus2: depths.actualLiquidity['2'],
        actualLiquidityMinus1: depths.actualLiquidity['-1'],
        actualLiquidityMinus2: depths.actualLiquidity['-2'],
      } as JsonLoanExchangeExtended;
    });
  },
  {
    devModeChecks: { identityFunctionCheck: 'never' },
  },
);
