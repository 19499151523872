import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { RootState } from 'src/store';

import { AUTH_BASE_URL } from 'src/constants';
import { ReauthBaseQuery } from './refresh-auth';
import { customHeaders } from './helpers';

const customBaseQuery = fetchBaseQuery({
  baseUrl: AUTH_BASE_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => customHeaders(headers, getState as () => RootState),
});

export const rtkAuthApi = createApi({
  reducerPath: 'api',
  tagTypes: ['Profile', 'Users', 'User', 'Roles', 'Role'],
  baseQuery: ReauthBaseQuery(customBaseQuery),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (builders) => ({}),
});
