import type { Middleware } from '@reduxjs/toolkit';
// import type { BuiltAction } from 'src/store/middleware/redux-websocket/actions';
import { wsLoansSend } from '../ws-loans/actions';

export enum WsChannel {
  Loans = 'loans',
}

const handleWsRequestMiddleware: Middleware = (store) => (next) => (action) => {
  const typedAction = action as any; // as BuiltAction<any>;

  if (typedAction.meta?.ws) {
    let actionCreator: (message: any) => any; // BuiltAction<any>;
    switch (typedAction.meta.ws) {
      case WsChannel.Loans.toString(): {
        actionCreator = wsLoansSend;
        break;
      }
      default:
        return next(typedAction);
    }

    store.dispatch(
      actionCreator({
        ...typedAction.payload,
      }),
    );
  }
  return next(typedAction);
};

export default handleWsRequestMiddleware;
