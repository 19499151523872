import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { GenericError } from 'src/store/utils/errors';
import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import type { RequestStatus } from 'src/store/types';

// import { listen } from '../actions';
import { login } from 'src/store/auth/actions';
import { ProjectCommands } from './types';

export interface JsonProjectExchange {
  id: number;
  bots: number;
}

export interface JsonProject {
  id: number;
  name: string;
  tabs: JsonProjectExchange[];
}

export interface RetainersProjectsState {
  requestStatus: RequestStatus;
  error: null | GenericError;

  list: JsonProject[];
  sortKey: string;
  searchInput: string;
}

const allowedCommands = Object.values(ProjectCommands);

const initialState: RetainersProjectsState = {
  requestStatus: null,
  error: null,

  list: [],
  // search and sort

  searchInput: '',
  sortKey: 'name_asc',
};

const retainersProjectsSlice = createSlice({
  name: 'projects', // retainers.projects
  initialState,
  reducers: {
    setSearchInput(state, action: PayloadAction<string>) {
      state.searchInput = action.payload;
    },
    setSortKey(state, action) {
      state.sortKey = action.payload;
    },
  },
  extraReducers: (builder) => {
    // NOTICE: this data comes on "login" command,
    // so there is no need to track the pending status on "listen" command

    builder.addCase(login.pending, (state /* , action: any */) => {
      // const { section } = action.meta.arg;
      if (state.requestStatus !== 'success') {
        state.requestStatus = 'pending';
      }
    });

    // on WS message

    builder.addCase(actionOnMessage, (state, action: PayloadAction<any>) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(
        `retainers/projects/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      const { command, okCommand } = json;

      if (error) {
        state.error = error;
        return;
      }

      if (command === ProjectCommands.Projects) {
        state.list = json.list;
        state.requestStatus = 'success';
        return;
      }

      if (command === 'ok') {
        switch (okCommand) {
          // case 'Projects':
          //   state.list = json.list;
          //   break;

          default:
        }
      }
    });
  },
});

export const retainersProjectsActions = { ...retainersProjectsSlice.actions };
// export const { toggleSidebar } = retainersProjectsSlice.actions;

export default retainersProjectsSlice.reducer;
