import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { RootState } from 'src/store';
import { DEX_API_URL } from 'src/constants';
import { customHeaders } from 'src/store/cold-wallets/rtkLoanColdWalletsApi/helpers';

import { ReauthBaseQuery } from 'src/store/shared/rtkAuthApi/refresh-auth';

const customBaseQuery = fetchBaseQuery({
  baseUrl: DEX_API_URL,
  prepareHeaders: (headers, { getState }) => customHeaders(headers, getState as () => RootState),
});

export const rtkColdWalletApi = createApi({
  reducerPath: 'coldWalletApi',
  tagTypes: ['LoanColdWallets', 'LoanColdWallet'],
  baseQuery: ReauthBaseQuery(customBaseQuery),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (builder) => ({}),
});
