import { createSlice } from '@reduxjs/toolkit';

import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';

import type { LoanFundsState, JsonLoanFundsSuccess } from './types';
import { LoanFundsCommands } from './types';

import type { AddCommittedFundsPayload } from './actions';
import { addCommittedFunds } from './actions';

const allowedCommands = Object.values(LoanFundsCommands);

const initialState: LoanFundsState = {
  committedFunds: [],
  initialCommittedFunds: null,
  status: null,
  error: null,
};

const loanFundsSlice = createSlice({
  name: 'loanFunds',
  initialState,
  reducers: {
    setInitialCommittedFunds(state, action: { payload: Omit<AddCommittedFundsPayload, 'loanID'> }) {
      state.initialCommittedFunds = action.payload.amount;
    },
    resetCommittedFundsStatus(state) {
      state.status = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addCommittedFunds, (state, action) => {
      console.log(`${addCommittedFunds.toString()}:`, action.payload);
      state.status = 'pending';
    });

    builder.addCase(actionOnMessage, (state, action: any) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(
        `loans/funds/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      const { command, okCommand } = json;

      if (error) {
        state.error = error;

        switch (errorCommand) {
          case LoanFundsCommands.AddCommittedFunds:
            state.status = 'error';
            break;

          default:
        }

        return;
      }

      if (command === LoanFundsCommands.LoanFunds) {
        const { funds } = json as JsonLoanFundsSuccess;
        state.committedFunds = funds;
        return;
      }

      if (command === 'ok' && okCommand === LoanFundsCommands.AddCommittedFunds) {
        state.status = 'success';
        state.initialCommittedFunds = null;
      }
    });
  },
});

export const loanFundsActions = { ...loanFundsSlice.actions, addCommittedFunds };

export default loanFundsSlice.reducer;
