import { createAsyncThunk } from '@reduxjs/toolkit';

import { wsLoansSend } from 'src/store/ws-loans/actions';
import { getTokenPayload } from 'src/store/shared/rtkAuthApi/helpers';
import { AuthCommands } from './types';

interface LoginPayload {
  token: string;
  accessToken: string;
}

// TODO: do we need this? we use mostly loginDomain now afaik
export const login = createAsyncThunk('auth/login', (data: LoginPayload, thunkApi) => {
  const { token, accessToken } = data;
  const { isMfaAuthenticated } = getTokenPayload(accessToken);
  thunkApi.dispatch(
    wsLoansSend({
      command: AuthCommands.Login,
      // loan: 1, // we send this only from loans part of page
      ...(token && { token }),
      ...(accessToken && isMfaAuthenticated /* && !token */ && { accessToken }),
    }),
  );
});

interface AuthPayload {
  user: string;
  password: string;
}

export const authPassword = createAsyncThunk('auth/password', (data: AuthPayload, thunkApi) => {
  thunkApi.dispatch(
    wsLoansSend({
      command: AuthCommands.Authorization,
      loan: 1,
      ...data,
    }),
  );

  return data;
});

interface Auth2faPayload {
  user: string;
  code: string;
}

export const auth2FA = createAsyncThunk('auth/2FA', (data: Auth2faPayload, thunkApi) => {
  thunkApi.dispatch(
    wsLoansSend({
      command: AuthCommands['2FA'],
      loan: 1,
      ...data,
    }),
  );

  return data;
});

export const logout = createAsyncThunk('auth/logout', async (data: any, thunkApi: any) => {
  await thunkApi.extra.persistor.purge();
  await thunkApi.extra.persistor.flush();
});

interface ResetPayload {
  user: string;
}

// TODO: implement reset password functionality
export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  (data: ResetPayload, thunkApi) => {
    console.debug('data:', data, thunkApi);
    // thunkApi.dispatch(
    //   wsLoansSend({
    //     command: 'Auth',
    //     loan: 1,
    //     ...data
    //   })
    // );
  },
);
